<template>
  <div class="container-fluid content">
    <div class="content-container">
      <!-- Sidebar Content -->
      <div class="content-sidebar">
        <user-sidebar></user-sidebar>
      </div>

      <!-- Content Section -->
      <div class="content-section">
        <div class="container-fluid">
          <!-- Header -->
          <user-header></user-header>

          <div v-if="!category">
            <no-content>
              Category does not exist.
            </no-content>
          </div>

          <div v-else>
            <div class="row">
              <div class="col-1">
                <i class="far fa-arrow-alt-circle-left fa-4x"
                  @click="$router.go(-1)">
                </i>
              </div>
              <div class="col-10">
                <h1 class="text-center"> {{ categoryTitle }} </h1>
              </div>
              <div class="col-1"></div>
            </div>

            <div class="row text-center text-lg-left" v-if="medias.length > 0">
              <app-media-list class="col-12 col-sm-6 col-md-4 col-lg-3
                                media-col"
                              v-for="(media, index) in medias"
                              :key="index"
                              :media="media">
              </app-media-list>
            </div>
            <div v-else>
              <no-content>
                That category is still not available.
              </no-content>
            </div>

            <div class="row" v-if="mediasCurrentPage !== mediasLastPage &&
                               medias.length > 0">
              <div class="col text-center">
                <b-button class="btn btn-h3-small btn-h3-blue
                                  text-center mt-3 mb-3"
                          type="button"
                          @click="loadMoreCategoryVideos">
                  Load More
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Report Feedback -->
    <div class="feedback-wrapper" v-if="showReportForm">
      <report-feedback @close-report-feedback="showReportFeedback" />
    </div>
    <fab
      :position="'bottom-right'"
      :bg-color="'#eaa627'"
      :main-icon="'feedback'"
      :main-tooltip="'Report Feedback'"
      :enable-rotation="true"
      @click.native="showReportFeedback"
    />
  </div>
</template>

<script>
  const UserSidebar = () => import('@/components/shared/UserSidebar.vue');
  const UserHeader = () => import('@/components/shared/UserHeader');
  const AppMediaList = () =>
  import('@/components/user/common/AppMediaList.vue');
  const NoContent = () => import('@/components/user/common/NoContent');
  const ReportFeedback = () => import('@/components/shared/ReportFeedback');

  import Fab from 'vue-fab';

  export default {
    name       : 'Category',
    components : {
      UserSidebar,
      UserHeader,
      AppMediaList,
      NoContent,
      ReportFeedback,
      Fab,
    },
    data() {
      return {
        category          : null,
        categoryTitle     : 'title',
        medias            : [],
        mediasPerPage     : 12,
        mediasCurrentPage : 0,
        mediasLastPage    : 0,
        showReportForm    : false,
      }
    },
    watch : {
      '$route.path'() {
        this.getCategoryDetails();
      },
    },
    methods : {

      /**
       * Get Category Details
       */
      getCategoryDetails() {
        this.$http.get('/api/category', {
          params : {
            id : this.$route.params.id,
          },
        }).then((res) => {
          this.category = res.data;
          this.categoryTitle = res.data.category_title;
          this.getCategoryVideos(0);
          this.$analytics.fbq.event('PageView', {
            'page'           : 'category-videos',
            'platform'       : 'website',
            'category_title' : res.data.category_title,
            'category_id'    : res.data.category_id,
          });
        }).catch(() => {
          this.category = null;
          this.categoryTitle = null;
          this.medias = [];
        });
      },

      /**
       * Get All Category Videos
       *
       * @param currentPage Pagination's current page
       */
      getCategoryVideos(currentPage = this.mediasCurrentPage) {
        this.$http.get('api/categoryvideo', {
          params : {
            categoryId : this.category.category_id,
            page       : currentPage + 1,
            perPage    : this.mediasPerPage,
          },
        }).then((response) => {
          this.mediasCurrentPage = response.data.result.currentPage;
          this.mediasLastPage = response.data.result.lastPage;

          if (this.mediasCurrentPage > 1) {
            const arr1 = this.medias;
            const arr2 = response.data.result.data;

            this.medias = arr1.concat(arr2);
          } else this.medias = response.data.result.data;
        }).catch(() => {
          this.medias = [];
        });
      },

      /**
       * Load More Category Videos
       */
      loadMoreCategoryVideos() {
        this.getCategoryVideos();
      },

      /**
       * Toggle Report Feedback form
       */
      showReportFeedback() {
        this.showReportForm = !this.showReportForm;
      },
    },
    created() {
      this.getCategoryDetails();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/media-list";
</style>
